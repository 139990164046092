import './Prizes.scss'

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearPrizesError,
  getPrizeCodeInfo,
  givePrizeToUser,
} from '../../actions/prizesActions'
import common from '../../common'

export default function Prizes() {
  const dispatch = useDispatch()

  const prizesState = useSelector(state => state.prizes)

  if (prizesState.error) {
    dispatch(clearPrizesError())
  }

  const [searched, setSearched] = useState(false)
  const [prizeCode, setPrizeCode] = useState()
  const [prizeCodeInfo, setPrizeCodeInfo] = useState()

  const searchPrizeCode = async () => {
    if (prizeCode) {
      const prizeCodeInfoResponse = await dispatch(getPrizeCodeInfo(prizeCode))
      setPrizeCodeInfo(prizeCodeInfoResponse)
      setSearched(true)
    }
  }

  const getCompletedDate = challengeSteps => {
    return challengeSteps
      .filter(x => x.Submit)
      .map(x => x.Submit.Created)
      .sort(function (a, b) {
        return Date.parse(a) < Date.parse(b)
      })[0]
  }

  const givePrize = async () => {
    await dispatch(
      givePrizeToUser(
        prizeCode,
        prizeCodeInfo.Challenge.ID,
        prizeCodeInfo.User.ID
      )
    )
    await searchPrizeCode()
  }

  const handleClearPrizeCode = () => {
    setPrizeCode('')
    setPrizeCodeInfo(null)
    setSearched(false)
  }

  return (
    <>
      <div id='prizes-top'>Prizes</div>

      <div id='prizes-content'>
        <div id='prize-code-search-box-wrapper'>
          <input
            id='prize-code-search-box'
            type='text'
            maxLength='15'
            value={prizeCode}
            onChange={e => setPrizeCode(e.target.value)}
            placeholder='Search prize code...'></input>

          {!prizeCodeInfo ? (
            <span
              id='prize-code-search-button'
              onClick={() => searchPrizeCode()}>
              Search
            </span>
          ) : (
            <span
              id='prize-code-clear-search-input'
              onClick={() => handleClearPrizeCode()}
            />
          )}
        </div>

        {!prizeCodeInfo && searched && (
          <div id='prize-code-not-found'>Prize code not found!</div>
        )}

        {prizeCodeInfo && (
          <div id='prize-code-content'>
            <div id='prize-code-user-info'>
              <img
                className='user-image'
                src={
                  prizeCodeInfo.User.ProfilePictureURL
                    ? `${common.imagesBaseUrl}/${prizeCodeInfo.User.ProfilePictureURL}`
                    : '/images/default-user.png'
                }
                alt='Profile pic'
              />
              <div className='prize-code-user-name-email'>
                <p>
                  <b>{prizeCodeInfo.User.FullName}</b>
                </p>
                <p>{prizeCodeInfo.User.Email}</p>
              </div>
            </div>

            <div>
              <div className='page-row'>
                <div className='row-left-column'>Location:</div>
                <div className='row-right-column'>
                  {prizeCodeInfo.Challenge.BusinessPin.LocationName}
                </div>
              </div>
              <div className='page-row'>
                <div className='row-left-column'>Challenge title:</div>
                <div className='row-right-column'>
                  {prizeCodeInfo.Challenge.Title}
                </div>
              </div>
              <div className='page-row'>
                <div className='row-left-column'>Prize:</div>
                <div className='row-right-column'>
                  {prizeCodeInfo.Challenge.Prize}
                </div>
              </div>
              <div className='page-row'>
                <div className='row-left-column'>Prize description:</div>
                <div className='row-right-column'>
                  {prizeCodeInfo.Challenge.PrizeDescription}
                </div>
              </div>
              <div className='page-row'>
                <div className='row-left-column'>No. of prizes:</div>
                <div className='row-right-column'>
                  {prizeCodeInfo.Challenge.NoOfPrizes}
                </div>
              </div>
              <div className='page-row'>
                <div className='row-left-column'>Completed:</div>
                <div className='row-right-column'>
                  {/* {prizeCodeInfo.Challenge.Created.substr(0, 10)} -<br />
                  {getCompletedDate(prizeCodeInfo.Challenge.Steps).substr(
                    0,
                    10
                  )} */}
                  {getCompletedDate(prizeCodeInfo.Challenge.Steps)}
                </div>
              </div>
              <div className='page-row'>
                <div className='row-left-column'>Description:</div>
                <div className='row-right-column'>
                  {prizeCodeInfo.Challenge.Description}
                </div>
              </div>
              <div className='page-row'>
                <div className='row-left-column'>Challenge tasks:</div>
                <div className='row-right-column'>
                  {prizeCodeInfo.Challenge.Steps?.map((x, index) => (
                    <div
                      className='prize-challenge-task'
                      // onClick={() => taskClicked(index)}
                    >
                      <span className='prize-challenge-task-position'>
                        {x.Position + 1}
                      </span>
                      <span>{x.ChallengeStepType.Name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div id='prize-code-status-wrapper'>
              {prizeCodeInfo.IsCollected ? (
                <div id='prize-code-status'>
                  <p>
                    <img
                      id='prize-given-checkmark'
                      src='images/green-checkmark.svg'
                      alt='checkmark'
                    />
                  </p>
                  <p>The prize has been given</p>
                  <p>
                    Go to challenges and create new offers for your customers!
                  </p>
                </div>
              ) : (
                <div id='prize-code-status'>
                  {/* <p>
                    <img
                      id='prize-given-checkmark'
                      src='images/green-checkmark.svg'
                      alt='checkmark'
                    />
                  </p> */}
                  <p>The prize has not been given</p>
                  <div className='give-prize-btn' onClick={() => givePrize()}>
                    Give Prize!
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
