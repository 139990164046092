import './ChallengeDetails.scss'

import ImageZoom from '@moorecp/react-medium-image-zoom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-responsive-modal'

import {
  deleteChallenge,
  getChallengeLocations,
  setCurrentChallengeTaskIndex,
  setCurrentChallengeTasks,
} from '../../actions/challengesActions'
import common from '../../common'
import { formatDate } from '../../utils/helpers'
import DeleteModal from '../DeleteModal/DeleteModal'

export default function ChallengeDetails(props) {
  let challenge = props.challenge
  const handleSecondPageContent = props.handleSecondPageContent
  const handleThirdPageContent = props.handleThirdPageContent

  const currentUser = useSelector(state => state.users.currentUser)
  const challengeLocations = useSelector(state => state.challenges.locations)
    ?.map(x => x.Name)
    .join(', ')

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedChallengeTaskIndex, setSelectedChallengeTaskIndex] = useState()
  const dispatch = useDispatch()

  const editClicked = () => {
    handleSecondPageContent('EditChallenge')
  }

  const taskClicked = taskIndex => {
    setSelectedChallengeTaskIndex(taskIndex)
    dispatch(setCurrentChallengeTaskIndex(taskIndex))
    handleThirdPageContent(
      'ChallengeTask',
      selectedChallengeTaskIndex !== taskIndex
    )
  }

  const proceedDeleteChallenge = () => {
    dispatch(deleteChallenge(challenge.ID, handleSecondPageContent))
    setDeleteModalOpen(false)
  }

  useEffect(() => {
    dispatch(setCurrentChallengeTasks(challenge.Steps))
  }, [dispatch, challenge])

  useEffect(() => {
    dispatch(getChallengeLocations(challenge.ID))
  }, [dispatch, challenge.ID])

  return (
    <>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        center>
        <DeleteModal
          error={
            challenge.NoOfParticipants > 0 &&
            !currentUser._isAdmin &&
            "You can't delete this challenge because it already has participants!"
          }
          element='challenge'
          setOpenModal={setDeleteModalOpen}
          proceedDelete={proceedDeleteChallenge}
        />
      </Modal>

      <div id='challenge-details-top'>
        <div id='challenge-name'>{challenge.Title}</div>
      </div>

      <div id='challenge-details-body'>
        <div className='page-row'>
          <div className='row-left-column black'>
            {challenge.NoOfParticipants} participants
          </div>
          <div className='row-right-column'>
            {challenge.NoOfFinishes} finished
          </div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Locations:</div>
          <div className='row-right-column'>{challengeLocations}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Description:</div>
          <div className='row-right-column'>{challenge.Description}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Prize:</div>
          <div className='row-right-column'>{challenge.Prize}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Prize description:</div>
          <div className='row-right-column'>{challenge.PrizeDescription}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>No of prizes:</div>
          <div className='row-right-column'>{challenge.NoOfPrizes}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>No of prizes left:</div>
          <div className='row-right-column'>{challenge.NoOfPrizesLeft}</div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Start date:</div>
          <div className='row-right-column'>
            {formatDate(challenge.StartDate)}
          </div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>End date:</div>
          <div className='row-right-column'>
            {formatDate(challenge.EndDate)}
          </div>
        </div>

        <div className='page-row'>
          <div className='row-left-column'>Tasks:</div>
          <div className='challenge-tasks'>
            {challenge.Steps?.map((x, index) => (
              <div
                key={index}
                className='challenge-task'
                onClick={() => taskClicked(index)}>
                <span className='challenge-task-position'>
                  {x.Position + 1}
                </span>
                <span>{x.ChallengeStepType.Name}</span>
              </div>
            ))}
          </div>
        </div>

        <div id='challenge-details-images-wrapper'>
          {challenge.ImageURL ? (
            <ImageZoom
              image={{
                src: `${common.imagesBaseUrl}/${challenge.ImageURL}`,
                className: 'challenge-details-image',
              }}
              zoomImage={{
                src: `${common.imagesBaseUrl}/${challenge.ImageURL}`,
              }}
            />
          ) : (
            <img
              className='challenge-details-image'
              src={'images/default-challenge.png'}
              alt='logo'></img>
          )}
        </div>
      </div>

      <div id='challenge-details-bottom'>
        <div
          id='delete-challenge-button'
          onClick={() => setDeleteModalOpen(true)}>
          Delete
        </div>
        <div id='edit-challenge-button' onClick={editClicked}>
          Edit
        </div>
      </div>
    </>
  )
}
