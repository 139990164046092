import 'react-day-picker/lib/style.css'

import './AddChallenge.scss'

import moment from 'moment/moment'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearChallengesError,
  getChallengeLocations,
  saveChallenge,
  setCurrentChallengeTaskIndex,
  setCurrentChallengeTasks,
} from '../../actions/challengesActions'
import common from '../../common'
import CustomSelect from '../CustomSelect/CustomSelect'

function CustomCalendarStart({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ width: 100 }}
      {...props}>
      <div className={classNames.overlay}>
        <input />
        {children}
      </div>
    </div>
  )
}

function CustomCalendarEnd({ classNames, selectedDay, children, ...props }) {
  return (
    <div
      className={classNames.overlayWrapper}
      style={{ marginLeft: -80, width: 100 }}
      {...props}>
      <div className={classNames.overlay}>
        <input />
        {children}
      </div>
    </div>
  )
}

CustomCalendarStart.propTypes = {
  classNames: PropTypes.object.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
  children: PropTypes.node.isRequired,
}

CustomCalendarEnd.propTypes = {
  classNames: PropTypes.object.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
  children: PropTypes.node.isRequired,
}

export default function AddChallenge(props) {
  const selectedChallenge = props.challenge
  const handleSecondPageContent = props.handleSecondPageContent
  const handleThirdPageContent = props.handleThirdPageContent

  const challengesState = useSelector(state => state.challenges)
  const selectedChallengeTasks = challengesState.currentChallengeTasks
  const locations = useSelector(state => state.locations.locations)
  const challengeLocations = useSelector(state => state.challenges.locations)

  // const [challenge, setChallenge] = useState()
  const [description, setDescription] = useState()
  const [selectedLocationIds, setSelectedLocationIds] = useState()
  const [showLocationDropdown, setShowLocationDropdown] = useState(false)
  const [title, setTitle] = useState()
  const [prize, setPrize] = useState()
  const [prizeDescription, setPrizeDescription] = useState()
  const [prizeCodes, setPrizeCodes] = useState()
  const [noOfPrizes, setNoOfPrizes] = useState(0)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedImage, setSelectedImage] = useState()
  const [selectedImageFormData, setSelectedImageFormData] = useState()
  const [selectedChallengeTaskIndex, setSelectedChallengeTaskIndex] = useState()
  const [havePrizeCodes, setHavePrizeCodes] = useState()
  const [singlePrizeCode, setSinglePrizeCode] = useState()
  const [givenPrizesNo, setGivenPrizesNo] = useState()

  const inputImageRef = useRef()
  const buttonHoldIntervalRef = useRef(null)

  const dispatch = useDispatch()

  if (challengesState.error) {
    alert(challengesState.error)
    dispatch(clearChallengesError())
  }

  const addTaskClicked = () => {
    handleThirdPageContent(
      'AddChallengeTask',
      selectedChallengeTaskIndex !== null
    )
    setSelectedChallengeTaskIndex(null)
    dispatch(setCurrentChallengeTaskIndex(null))
  }

  const taskClicked = taskIndex => {
    setSelectedChallengeTaskIndex(taskIndex)
    dispatch(setCurrentChallengeTaskIndex(taskIndex))
    handleThirdPageContent(
      'AddChallengeTask',
      selectedChallengeTaskIndex !== taskIndex
    )
  }

  const removeTask = taskIndex => {
    dispatch(
      setCurrentChallengeTasks(
        selectedChallengeTasks.filter((x, index) => index !== taskIndex)
      )
    )
    handleThirdPageContent(null)
  }

  const handleImageRemoval = () => {
    setSelectedImage(null)
    setSelectedImageFormData(null)
  }

  const fileSelectedHandler = event => {
    if (event.target.files.length !== 0) {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.readyState === 2) {
          setSelectedImage(reader.result)
        }
      }
      reader.readAsDataURL(event.target.files[0])
      setSelectedImageFormData(event.target.files[0])
    }
  }

  const handleSaveChallenge = () => {
    if (
      selectedLocationIds &&
      title &&
      prize &&
      prizeDescription &&
      description &&
      noOfPrizes &&
      startDate &&
      endDate &&
      selectedChallengeTasks?.length > 0 &&
      validPrizeCodes()
    ) {
      let editedChallenge = selectedChallenge
        ? {
            // edit
            ID: selectedChallenge.ID,
            BusinessPinIDs: selectedLocationIds,
            Title: title,
            Description: description,
            Prize: prize,
            PrizeDescription: prizeDescription,
            NoOfPrizes: noOfPrizes,
            StartDate: startDate,
            EndDate: endDate,
            ImageURL: selectedImage,
            Steps: selectedChallengeTasks,
            PrizeCodes: prizeCodes ? prizeCodes?.split('\n') : null,
            SinglePrizeCode: singlePrizeCode,
          }
        : {
            // new
            BusinessPinIDs: selectedLocationIds,
            Title: title,
            Description: description,
            Prize: prize,
            PrizeDescription: prizeDescription,
            NoOfPrizes: noOfPrizes,
            StartDate: startDate.toISOString().split('T')[0],
            EndDate: endDate.toISOString().split('T')[0],
            Steps: selectedChallengeTasks,
            PrizeCodes: prizeCodes ? prizeCodes?.split('\n') : null,
            SinglePrizeCode: singlePrizeCode,
          }

      dispatch(
        saveChallenge(
          editedChallenge,
          selectedImageFormData,
          handleSecondPageContent
        )
      )
    }
  }

  const handleChangeTitle = e => {
    let newInput = e.target.value
    setTitle(newInput || '')
  }

  const handleChangePrize = e => {
    let newInput = e.target.value
    setPrize(newInput || '')
  }

  const handleChangePrizeDescription = e => {
    let newInput = e.target.value
    setPrizeDescription(newInput || '')
  }

  function startChangeNoOfPrizes(number, fast) {
    handleChangeNoOfPrizes(number)

    let fastSpeedCount = 10
    let intervalId = setInterval(
      () => {
        handleChangeNoOfPrizes(number)

        fastSpeedCount--
        if (fastSpeedCount === 0 && !fast) {
          stopChangeNoOfPrizes()
          startChangeNoOfPrizes(number, true)
        }
      },
      fast ? 50 : 200
    )

    buttonHoldIntervalRef.current = intervalId
  }

  function stopChangeNoOfPrizes() {
    clearInterval(buttonHoldIntervalRef.current)
  }

  const handleChangeNoOfPrizes = number => {
    // number = 1 || -1

    if (noOfPrizes === 0 && number === -1) {
      return
    }

    setNoOfPrizes(noOfPrizes =>
      noOfPrizes + number > 0 ? noOfPrizes + number : 0
    )
  }

  const locationsDropdownClicked = () => {
    setShowLocationDropdown(!showLocationDropdown)
  }

  const handleAddImage = () => {
    inputImageRef.current.click()
  }

  const valuesPerLineAreEqualTo = (valuesString, number) => {
    const values = valuesString?.split('\n')

    const allValuesNotNullOrEmpty = values?.every(x => x !== null && x !== '')

    return allValuesNotNullOrEmpty && values?.length === number
  }

  const validPrizeCodes = () => {
    if (!havePrizeCodes) {
      return true
    }

    if (singlePrizeCode) {
      return valuesPerLineAreEqualTo(prizeCodes, 1)
    } else {
      return valuesPerLineAreEqualTo(
        prizeCodes,
        noOfPrizes - (givenPrizesNo || 0)
      )
    }
  }

  const getPrizeCodesText = () => {
    if (singlePrizeCode) {
      return 'one prize code'
    }

    return `${noOfPrizes - (givenPrizesNo || 0)}  prize codes (${
      givenPrizesNo || 0
    } given)`
  }

  const handleHaveInstantPrizeCodesCheckbox = havePrizeCodes => {
    setHavePrizeCodes(havePrizeCodes)

    if (!havePrizeCodes) {
      setPrizeCodes()
      setSinglePrizeCode()
    }
  }

  useEffect(() => {
    if (selectedChallenge) {
      setTitle(selectedChallenge.Title)
      setDescription(selectedChallenge.Description)
      setPrize(selectedChallenge.Prize)
      setPrizeDescription(selectedChallenge.PrizeDescription)
      setNoOfPrizes(selectedChallenge.NoOfPrizes)
      setPrizeCodes(selectedChallenge.PrizeCodes?.join('\n') || '')
      setStartDate(selectedChallenge.StartDate)
      setEndDate(selectedChallenge.EndDate)
      setHavePrizeCodes(!!selectedChallenge.PrizeCodes?.length)
      setSinglePrizeCode(selectedChallenge.SinglePrizeCode)
      setGivenPrizesNo(
        selectedChallenge.NoOfPrizes - selectedChallenge.NoOfPrizesLeft
      )
      dispatch(getChallengeLocations(selectedChallenge.ID))

      if (selectedChallenge.ImageURL) {
        setSelectedImage(selectedChallenge.ImageURL)
      }

      dispatch(setCurrentChallengeTasks(selectedChallenge.Steps))
    } else {
      dispatch(setCurrentChallengeTasks(null))
    }
  }, [dispatch, selectedChallenge])

  return (
    <>
      <div id='add-location-top'>Challenge Information</div>

      <div id='add-location-body'>
        <ClickAwayListener onClickAway={() => setShowLocationDropdown(false)}>
          <div
            id='select-location'
            className='form-control text-input add-input'
            onClick={() => setShowLocationDropdown(true)}>
            <CustomSelect
              defaultText='Location'
              optionList={locations[0]}
              showOptions={showLocationDropdown}
              setShowOptions={setShowLocationDropdown}
              setSelectedData={setSelectedLocationIds}
              preselectedData={challengeLocations}
              multiple={true}
            />
            <div
              className='select-arrow-wrapper'
              onClick={e => {
                e.stopPropagation()
                locationsDropdownClicked()
              }}>
              <img
                className='select-arrow'
                alt='select arrow'
                src={
                  showLocationDropdown
                    ? 'images/close-select-arrow.svg'
                    : 'images/open-select-arrow.svg'
                }
              />
            </div>
          </div>
        </ClickAwayListener>

        <input
          type='text'
          name='title'
          placeholder='Challenge title'
          aria-label='Title'
          className='form-control text-input add-input'
          id='challenge-title'
          onChange={handleChangeTitle}
          value={title}
          maxLength='50'
          autoComplete='off'
        />
        <div id='title-chars-restriction'>max 50 characters</div>

        <input
          type='text'
          name='prize'
          placeholder='Prize'
          aria-label='Prize'
          className='form-control text-input add-input'
          id='challenge-prize'
          onChange={handleChangePrize}
          value={prize}
          autoComplete='off'
        />

        <input
          type='text'
          name='prize'
          placeholder='Prize description'
          aria-label='Prize description'
          className='form-control text-input add-input'
          id='challenge-prize-description'
          onChange={handleChangePrizeDescription}
          value={prizeDescription}
          autoComplete='off'
        />

        <div id='select-no-of-prizes'>
          <span id='no-of-prizes-title'>No. of prizes</span>

          <div id='no-of-prizes-controls'>
            <button
              onMouseDown={() => startChangeNoOfPrizes(-1)}
              onMouseUp={() => stopChangeNoOfPrizes()}
              onTouchStart={() => startChangeNoOfPrizes(-1)}
              onTouchEnd={() => stopChangeNoOfPrizes()}
              className={noOfPrizes === 0 ? 'disabled-button' : ''}>
              -
            </button>
            <span id='no-of-prizes-display'>{noOfPrizes}</span>
            <button
              onMouseDown={() => startChangeNoOfPrizes(1)}
              onMouseUp={() => stopChangeNoOfPrizes()}
              onTouchStart={() => startChangeNoOfPrizes(1)}
              onTouchEnd={() => stopChangeNoOfPrizes()}>
              +
            </button>
          </div>
        </div>

        <div id='instan-prize-codes-checkboxes'>
          <div className='checkbox'>
            <input
              type='checkbox'
              id='instant-prize-codes'
              name='instan-prize-codes-checkbox'
              checked={havePrizeCodes}
              onChange={() =>
                handleHaveInstantPrizeCodesCheckbox(!havePrizeCodes)
              }
            />
            <label
              htmlFor='instant-prize-codes'
              id='instan-prize-codes-label'
              className='disable-select'>
              <span>Instant prize codes</span>
            </label>
          </div>

          {havePrizeCodes && (
            <div className='checkbox'>
              <input
                type='checkbox'
                id='single-code'
                name='single-code-checkbox'
                checked={singlePrizeCode}
                onChange={() => setSinglePrizeCode(!singlePrizeCode)}
              />
              <label
                htmlFor='single-code'
                id='single-code-label'
                className='disable-select'>
                <span>Single code</span>
              </label>
            </div>
          )}
        </div>

        {havePrizeCodes && (
          <>
            <textarea
              id='instan-prize-codes-list'
              className='form-control text-multiline-input'
              name='prize-codes'
              placeholder='Instant prize codes'
              value={prizeCodes}
              onChange={e => setPrizeCodes(e.target.value || '')}></textarea>
            <div id='textarea-info'>{getPrizeCodesText()}</div>
          </>
        )}

        <div id='select-dates'>
          <div id='start-date'>
            <DayPickerInput
              placeholder='Start date'
              dayPickerProps={{
                disabledDays: {
                  before: new Date(),
                },
                fromMonth: new Date(),
              }}
              overlayComponent={CustomCalendarStart}
              onDayChange={date => setStartDate(date)}
              value={startDate}
            />
            <img
              className='calendar-icon'
              src='images/calendar-icon.svg'
              alt='start date'
            />
          </div>

          <div id='end-date'>
            <DayPickerInput
              placeholder='End date'
              dayPickerProps={{
                disabledDays: {
                  before: moment(startDate).toDate() || new Date(),
                },
                fromMonth: moment(startDate).toDate() || new Date(),
              }}
              overlayComponent={CustomCalendarEnd}
              onDayChange={date => setEndDate(date)}
              value={endDate}
            />
            <img
              className='calendar-icon'
              src='images/calendar-icon.svg'
              alt='end date'
            />
          </div>
        </div>

        <textarea
          id='challenge-description'
          className='form-control text-multiline-input'
          name='description'
          placeholder='Challenge description'
          maxLength='250'
          value={description}
          onChange={e => setDescription(e.target.value || '')}></textarea>
        <div id='chars-restriction'>max 250 characters</div>

        <p id='challenge-tasks-label'>Challenge tasks</p>

        <div className='challenge-tasks'>
          {selectedChallengeTasks?.map((x, index) => (
            <div
              key={index}
              className='challenge-task'
              onClick={e => {
                if (e.currentTarget === e.target) {
                  taskClicked(index)
                }
              }}>
              <span
                className='challenge-task-position'
                onClick={() => taskClicked(index)}>
                {index + 1}
              </span>
              <span onClick={() => taskClicked(index)}>
                {x.ChallengeStepType.Name}
              </span>
              <span
                onClick={() => removeTask(index)}
                className='challenge-task-remove'>
                X
              </span>
            </div>
          ))}
        </div>

        <div id='add-tasks-button'>
          <button onClick={() => addTaskClicked()}>+ Add</button>
        </div>

        <div className='browse-challenge-photo-wrapper'>
          {/* Browse Photo */}
          {!selectedImage && (
            <>
              <div
                className='browse-challenge-photo-round-wrapper'
                onClick={handleAddImage}>
                <img
                  id='plus'
                  src='images/plus_logo_business_dark.svg'
                  alt='+'
                />
              </div>

              <div className='browse-challenge-photo-icon'>
                <label className='browse-challenge-photo-input'>
                  <input
                    ref={inputImageRef}
                    type='file'
                    onChange={fileSelectedHandler}
                    accept='image/*'
                  />
                </label>
                <img src='images/photo_camera-24px.svg' alt='browse icon'></img>
                <p>Photo</p>
              </div>
            </>
          )}

          {/* Show Photo */}
          {selectedImage && (
            <div className='preview-challenge-photo-wrapper'>
              <img
                id='preview-challenge-photo'
                src={
                  selectedImage.startsWith('data:image')
                    ? selectedImage
                    : `${common.imagesBaseUrl}/${selectedImage}`
                }
                alt='challenge img'
              />

              <div id='delete-image-button' onClick={handleImageRemoval}>
                <img
                  id='delete-image'
                  src='images/trash_black.svg'
                  alt='delete'></img>
              </div>
            </div>
          )}
        </div>
      </div>

      <div id='add-challenge-bottom'>
        <div
          id='cancel-button'
          onClick={() =>
            handleSecondPageContent(selectedChallenge ? 'ChallengeDetails' : '')
          }>
          Cancel
        </div>
        <div
          id={
            selectedLocationIds &&
            selectedLocationIds.length &&
            title &&
            prize &&
            prizeDescription &&
            description &&
            noOfPrizes &&
            startDate &&
            endDate &&
            selectedChallengeTasks?.length > 0 &&
            validPrizeCodes()
              ? 'save-btn-enabled'
              : 'save-btn-disabled'
          }
          onClick={handleSaveChallenge}>
          Save
        </div>
      </div>
    </>
  )
}
